import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import dashboard from './slices/dashboardSlice';
import AbSlice from './slices/AbSlice';
import OsSlice from './slices/OsSlice';
import RoundRobinSlice from './slices/RoundRobinSlice';
import SubscriptionSlice from './slices/SubscriptionSlice';
import SplashLinkSlice from './slices/SplashLinkSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        dashboard,
        AbSlice,
        OsSlice,
        RoundRobinSlice,
        SubscriptionSlice,
        SplashLinkSlice,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
