import fetch from 'auth/FetchInterceptor';

const RoundRobinService = {};

RoundRobinService.getCampaigns = function () {
  return fetch({
    url: '/api/campaigns/rr',
    method: 'get',
  })
}

RoundRobinService.updateCampaign = function (data) {
  return fetch({
    url: `/api/campaigns/rr`,
    method: 'post',
    data:data
  })
}

RoundRobinService.getCampaign = function (campaignId) {
  return fetch({
    url: `/api/campaigns/${campaignId}`,
    method: 'get'
  })
}

RoundRobinService.startSubscriptionSession = function (data) {
  return fetch({
    url: `/api/stripe/subscriptions`,
    method: 'post',
    data:data
  })
}

export default RoundRobinService;