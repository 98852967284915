import React, { useState, useEffect } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import {setRewardfulCode} from 'store/slices/authSlice';

const Routes = () => {
	const dispatch = useDispatch();
	const queryParams = new URLSearchParams(window.location.search);
	const rewardfulCode = queryParams.get('via');
	const [referral, setReferral] = useState(null);
	useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    window.addEventListener('Rewardful.initialized', function () {
      setReferral(window.Rewardful.referral)
			dispatch(setRewardfulCode((window.Rewardful.referral)))
    })
    console.log("REFERRAL IS:",window.Rewardful.referral);
		dispatch(setRewardfulCode(window.Rewardful.referral))
  }, []);
	
	localStorage.setItem('rewardful', rewardfulCode);
	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
				{protectedRoutes.map((route, index) => {
					return (
						<Route 
							key={route.key + index} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/>
					)
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route 
							key={route.path} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/>
					)
				})}
			</Route>
		</RouterRoutes>
	)
}

export default Routes