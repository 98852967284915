import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OsService from 'services/OsService';

export const initialState = {
	loading: false,
	message: 'Operating System Slice',
	showMessage: false,
	success:false,
  campaigns:[],
  campaignEditModalDisplay: false,
  editCampaign: {},
  campaignTypes: [],
  campaignLoading: false,
  campaignSuccess:false,
  campaignUpdateLoading:false,
  campaignUpdateSuccess:false,
  startSubscriptionSuccess:false,
  startSubscriptionLoading:false,
  startSubscriptionData:[],
  showOsCampaignModalFlag:false
}

export const getCampaigns = createAsyncThunk('osrouting/campaigns',async (_, { rejectWithValue }) => {
	try {
		const response = await OsService.getCampaigns();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getCampaignTypes = createAsyncThunk('osrouting/campaigns/types',async (_, { rejectWithValue }) => {
	try {
		const response = await OsService.getCampaignTypes();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getCampaign = createAsyncThunk('osrouting/campaign',async (campaign_id, { rejectWithValue }) => {
	try {
		const response = await OsService.getCampaign(campaign_id);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateCampaign = createAsyncThunk('osrouting/updatecampaign',async (data, { rejectWithValue }) => {
	try {
		const response = await OsService.updateCampaign(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const OsSlice = createSlice({
  name: 'osslice',
  initialState,
  reducers:{
    showLoading:(state)=>{
      state.loading = true
    },
    getCampaignsSuccess: (state,action)=>{
      state.loading = false
      state.campaigns = action.payload
      state.success = true
    },
    showOsCampaignModal:(state)=>{
      state.showOsCampaignModalFlag = true
    },
    hideOsCampaignModal:(state)=>{
      state.showOsCampaignModalFlag = false
    },
    clearEditCampaign:(state)=>{
      state.editCampaign = {}
    },
    resetCampaignUpdateSuccess:(state)=>{
      state.campaignUpdateSuccess = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaigns.fulfilled, (state,action) => {
        state.loading = false
        state.success = true
        state.campaigns = action.payload
      })
      .addCase(getCampaigns.rejected, (state) => {
        state.loading = false
        state.success = false
      })
      .addCase(getCampaign.pending, (state) => {
        state.campaignLoading = true
      })
      .addCase(getCampaign.fulfilled, (state,action) => {
        state.campaignLoading = false
        state.campaignSuccess = true
        state.editCampaign = action.payload
      })
      .addCase(getCampaign.rejected, (state) => {
        state.campaignLoading = false
        state.campaignSuccess = false
      })
      .addCase(getCampaignTypes.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignTypes.fulfilled, (state,action) => {
        state.loading = false
        state.success = true
        state.campaignTypes = action.payload
      })
      .addCase(getCampaignTypes.rejected, (state) => {
        state.loading = false
        state.success = false
      })
      .addCase(updateCampaign.pending, (state) => {
        state.campaignUpdateLoading = true
      })
      .addCase(updateCampaign.fulfilled, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = true
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = false
        state.showMessage = true
      })
  }
})

export const { 
	showLoading,
  showCampaignUpdateModal,
  hideCampaignUpdateModal,
  clearEditCampaign,
  showOsCampaignModal,
  hideOsCampaignModal,
  resetCampaignUpdateSuccess
} = OsSlice.actions

export default OsSlice.reducer