import fetch from 'auth/FetchInterceptor';

const SubscriptionService = {};

SubscriptionService.startSubscriptionSession = function (data) {
  return fetch({
    url: `/api/stripe/subscriptions`,
    method: 'post',
    data:data
  })
}

SubscriptionService.cancelSubscriptionSession = function (data) {
  return fetch({
    url: `/api/stripe/subscriptions`,
    method: 'put',
    data:data
  })
}

export default SubscriptionService;