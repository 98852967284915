import fetch from 'auth/FetchInterceptor';

const DashboardService = {};

DashboardService.getProducts = function () {
  return fetch({
    url: '/api/stripe/products',
    method: 'get',
  })
}

DashboardService.getSubscriptions = function (customer) {
  return fetch({
    url: `/api/stripe/subscriptions/${customer}`,
    method: 'get',
  })
}

DashboardService.getUserProducts = function () {
  return fetch({
    url: '/api/users/products',
    method: 'get',
  })
}

DashboardService.getCampaigns = function () {
  return fetch({
    url: '/campaigns',
    method: 'get',
  })
}

DashboardService.getCampaignTypes = function () {
  return fetch({
    url: '/campaigns/types',
    method: 'get',
  })
}

DashboardService.updateCampaign = function (data) {
  return fetch({
    url: `/campaigns`,
    method: 'post',
    data:data
  })
}

DashboardService.getCampaign = function (campaignId) {
  return fetch({
    url: `/campaigns/${campaignId}`,
    method: 'get'
  })
}

DashboardService.deleteCampaign = function (campaignId) {
  return fetch({
    url: `/api/campaigns/${campaignId}`,
    method: 'delete'
  })
}

DashboardService.toggleCampaign = function (data) {
  return fetch({
    url: `/api/campaigns/toggleactive`,
    method: 'post',
    data:data
  })
}
DashboardService.resetVisits = function (campaignId) {
  return fetch({
    url: `/api/campaigns/resetvisits`,
    method: 'post',
    data:{campaignId}
  })
}

export default DashboardService;

