import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubscriptionService from 'services/SubscriptionService';

export const initialState = {
	loading: false,
  startSubscriptionSuccess:false,
  startSubscriptionLoading:false,
  startSubscriptionData:[],
  showSubscriptionManageModalFlag: false,
  cancelSubscriptionsSuccess: false,
  cancelSubscriptionsloading:false,
}

export const startSubscription = createAsyncThunk('subscriptions/startSubscription',async (data, { rejectWithValue }) => {
	try {
		const response = await SubscriptionService.startSubscriptionSession(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const cancelSubscriptions = createAsyncThunk('subscriptions/cancelSubscription',async (data, { rejectWithValue }) => {
	try {
		const response = await SubscriptionService.cancelSubscriptionSession(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const SubscriptionSlice = createSlice({
  name: 'subscriptionslice',
  initialState,
  reducers:{
    showLoading:(state)=>{
      state.loading = true
    },
    showSubscriptionManageModal:(state)=>{
      state.showSubscriptionManageModalFlag = true
    },
    hideSubscriptionManageModal:(state)=>{
      state.showSubscriptionManageModalFlag = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startSubscription.pending, (state) => {
        state.startSubscriptionLoading = true
      })
      .addCase(startSubscription.fulfilled, (state,action) => {
        state.startSubscriptionLoading = false
        state.startSubscriptionSuccess = true
        state.startSubscriptionData = action.payload
      })
      .addCase(startSubscription.rejected, (state) => {
        state.startSubscriptionLoading = false
        state.startSubscriptionSuccess = false
      })
      .addCase(cancelSubscriptions.pending, (state) => {
        state.cancelSubscriptionsloading = true
      })
      .addCase(cancelSubscriptions.fulfilled, (state,action) => {
        state.cancelSubscriptionsloading = false
        state.cancelSubscriptionsSuccess = true
      })
      .addCase(cancelSubscriptions.rejected, (state) => {
        state.startSubscriptionLoading = false
        state.cancelSubscriptionsSuccess = false
      })
  }
})

export const { 
	showLoading,showSubscriptionManageModal,hideSubscriptionManageModal
} = SubscriptionSlice.actions

export default SubscriptionSlice.reducer