const dev = {
  API_ENDPOINT_URL: 'http://localhost:3005',
	STRIPE_PREFIX: 'http://localhost:3000',
	STRIPE_PK: 'pk_test_51NNNewK62DcD5YsiOOYlkAF9njXnvS0y8dvANFED5Nm5bX6wsPevho1d3ZgD6czYA1aeQRnl7cuzOofo5P9pmMe6006gpPsd7k'
};

const prod = {
  API_ENDPOINT_URL: 'https://fwrd.tech',
	STRIPE_PREFIX: 'https://fwrd.tech',
	STRIPE_PK: 'pk_live_51NNNewK62DcD5YsiVlBhFApsHwOXo4pbhlymLlp1a9Wxtx0u7VXNiBxEkwuLj6xwZN8dPK2djOVQCFBsNydO3DoE00hi4Uyzwk'
};

const test = {
  API_ENDPOINT_URL: '/api',
	STRIPE_PK: 'pk_test_51NNNewK62DcD5YsiOOYlkAF9njXnvS0y8dvANFED5Nm5bX6wsPevho1d3ZgD6czYA1aeQRnl7cuzOofo5P9pmMe6006gpPsd7k'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
