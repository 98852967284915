import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ABService from 'services/ABService';

export const initialState = {
	loading: false,
	message: 'AB Slice',
	showMessage: false,
	success:false,
  campaigns:[],
  campaignEditModalDisplay: false,
  editCampaign: {},
  campaignTypes: [],
  campaignLoading: false,
  campaignSuccess:false,
  campaignUpdateLoading:false,
  campaignUpdateSuccess:false,
  showAbCampaignModalFlag:false
}

export const getCampaigns = createAsyncThunk('abrouting/campaigns',async (_, { rejectWithValue }) => {
	try {
		const response = await ABService.getCampaigns();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getCampaign = createAsyncThunk('abrouting/campaign',async (campaign_id, { rejectWithValue }) => {
	try {
		const response = await ABService.getCampaign(campaign_id);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateCampaign = createAsyncThunk('abrouting/updatecampaign',async (data, { rejectWithValue }) => {
	try {
		const response = await ABService.updateCampaign(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const AbSlice = createSlice({
  name: 'abslice',
  initialState,
  reducers:{
    showLoading:(state)=>{
      state.loading = true
    },
    getCampaignsSuccess: (state,action)=>{
      state.loading = false
      state.campaigns = action.payload
      state.success = true
    },
    showAbCampaignModal:(state)=>{
      state.showAbCampaignModalFlag = true
    },
    hideAbCampaignModal:(state)=>{
      state.showAbCampaignModalFlag = false
    },
    clearEditCampaign:(state)=>{
      state.editCampaign = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaigns.fulfilled, (state,action) => {
        state.loading = false
        state.success = true
        state.campaigns = action.payload
      })
      .addCase(getCampaigns.rejected, (state) => {
        state.loading = false
        state.success = false
      })
      .addCase(getCampaign.pending, (state) => {
        state.campaignLoading = true
      })
      .addCase(getCampaign.fulfilled, (state,action) => {
        state.campaignLoading = false
        state.campaignSuccess = true
        state.editCampaign = action.payload
      })
      .addCase(getCampaign.rejected, (state) => {
        state.campaignLoading = false
        state.campaignSuccess = false
      })
      .addCase(updateCampaign.pending, (state) => {
        state.campaignUpdateLoading = true
      })
      .addCase(updateCampaign.fulfilled, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = true
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = false
        state.showMessage = true
      })
  }
})

export const { 
	showLoading,
  showAbCampaignModal,
  hideAbCampaignModal,
  clearEditCampaign,
} = AbSlice.actions

export default AbSlice.reducer