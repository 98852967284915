import fetch from 'auth/FetchInterceptor';

const SplashLinkService = {};

SplashLinkService.getLinks = function () {
  return fetch({
    url: '/api/splashlinks',
    method: 'get',
  })
}

SplashLinkService.getLink = function (campaignId) {
  return fetch({
    url: `/api/splashlinks/${campaignId}`,
    method: 'get',
  })
}

SplashLinkService.updateSplashLink = function (data) {
  console.log("DATA IN UPDATE SPLASH LINK:",data);
  return fetch({
    url: `/api/splashlinks/`,
    method: 'post',
    data:data
  })
}

SplashLinkService.deleteImage = function (data) {
  return fetch({
    url: `/api/splashlinks/image/${data}`,
    method: 'delete',
    data:data
  })
}
SplashLinkService.resetVisits = function (campaignId) {
  
  return fetch({
    url: `/api/splashlinks/visits`,
    method: 'post',
    data:{campaignId}
  })
}

export default SplashLinkService;