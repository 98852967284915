import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.abrouting',
        path: `${APP_PREFIX_PATH}/dashboards/abrouting`,
        component: React.lazy(() => import('views/app-views/dashboards/abrouting')),
    },
    {
        key: 'dashboard.roundrobinrouting',
        path: `${APP_PREFIX_PATH}/dashboards/roundrobinrouting`,
        component: React.lazy(() => import('views/app-views/dashboards/roundrobinrouting')),
    },
    {
        key: 'dashboard.osrouting',
        path: `${APP_PREFIX_PATH}/dashboards/osrouting`,
        component: React.lazy(() => import('views/app-views/dashboards/osrouting')),
    },
    {
        key: 'dashboard.subscriptionmanage',
        path: `${APP_PREFIX_PATH}/dashboards/subscriptionmanage`,
        component: React.lazy(() => import('views/app-views/dashboards/SubscriptionManage')),
    },
    {
        key: 'dashboard.splashlinks',
        path: `${APP_PREFIX_PATH}/dashboards/splashlinks`,
        component: React.lazy(() => import('views/app-views/dashboards/splashlink')),
    },

]