import fetch from 'auth/FetchInterceptor';

const OsService = {};

OsService.getCampaigns = function () {
  return fetch({
    url: '/api/campaigns/os',
    method: 'get',
  })
}

OsService.updateCampaign = function (data) {
  return fetch({
    url: `/api/campaigns/os`,
    method: 'post',
    data:data
  })
}

OsService.getCampaign = function (campaignId) {
  return fetch({
    url: `/api/campaigns/${campaignId}`,
    method: 'get'
  })
}

export default OsService;