import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DashboardService from 'services/DashboardService';

export const initialState = {
	loading: false,
	message: 'Hello World',
	showMessage: false,
	success:false,
  campaigns:[],
  campaignEditModalDisplay: false,
  editCampaign: {},
  campaignTypes: [],
  campaignLoading: false,
  campaignSuccess:false,
  campaignUpdateLoading:false,
  campaignUpdateSuccess:false,
  products: {},
  productsSuccess:false,
  userProducts: {},
  userProductsSuccess: false,
  subscriptionsLoading: false,
  subscriptionsSuccess:false,
  subscriptions:[],
  campaignDeleteLoading: false,
  campaignDeleteSuccess:false,
  campaignToggleLoading:false,
  campaignToggleSuccess:false
}

export const getProducts = createAsyncThunk('dashboard/products',async (_, { rejectWithValue }) => {
	try {
		const response = await DashboardService.getProducts();
    return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSubscriptions = createAsyncThunk('dashboard/subscriptions',async (_, { rejectWithValue }) => {
	try {
		const response = await DashboardService.getSubscriptions();
    return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getUserProducts = createAsyncThunk('dashboard/userproducts',async (_, { rejectWithValue }) => {
  
	try {
		const response = await DashboardService.getUserProducts();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getCampaigns = createAsyncThunk('dashboard/campaigns',async (_, { rejectWithValue }) => {
	try {
		const response = await DashboardService.getCampaigns();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getCampaignTypes = createAsyncThunk('dashboard/campaigns/types',async (_, { rejectWithValue }) => {
	try {
		const response = await DashboardService.getCampaignTypes();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getCampaign = createAsyncThunk('dashboard/campaign',async (campaign_id, { rejectWithValue }) => {
	try {
		const response = await DashboardService.getCampaign(campaign_id);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateCampaign = createAsyncThunk('dashboard/updatecampaign',async (data, { rejectWithValue }) => {
	try {
		const response = await DashboardService.updateCampaign(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteCampaign = createAsyncThunk('dashboard/deletecampaign',async (data, { rejectWithValue }) => {
	try {
		const response = await DashboardService.deleteCampaign(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const activeToggle = createAsyncThunk('dashboard/activetoggle',async (data, { rejectWithValue }) => {
	try {
		const response = await DashboardService.toggleCampaign(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const resetVisits = createAsyncThunk('dashboard/resetvisits',async (data, { rejectWithValue }) => {
	try {
		const response = await DashboardService.resetVisits(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers:{
    showLoading:(state)=>{
      state.loading = true
    },
    getCampaignsSuccess: (state,action)=>{
      state.loading = false
      state.campaigns = action.payload
      state.success = true
    },
    showCampaignUpdateModal:(state)=>{
      state.campaignEditModalDisplay = true
    },
    hideCampaignUpdateModal:(state)=>{
      state.campaignEditModalDisplay = false
    },
    clearEditCampaign:(state)=>{
      state.editCampaign = {}
    },
    resetDeleteSuccess:(state=>{
      state.campaignDeleteSuccess = false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true
        state.productsSuccess = false
      })
      .addCase(getProducts.fulfilled, (state,action) => {
        state.loading = false
        state.productsSuccess = true
        state.products = action.payload
      })
      .addCase(getProducts.rejected, (state) => {
        state.loading = false
        state.productsSuccess = false
      })
      .addCase(getSubscriptions.pending, (state) => {
        state.subscriptionsLoading = true
        state.subscriptionsSuccess = false
      })
      .addCase(getSubscriptions.fulfilled, (state,action) => {
        state.subscriptionsLoading = false
        state.subscriptionsSuccess = true
        state.subscriptions = action.payload
      })
      .addCase(getSubscriptions.rejected, (state) => {
        state.subscriptionsLoading = false
        state.subscriptionsSuccess = false
      })
      .addCase(getUserProducts.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserProducts.fulfilled, (state,action) => {
        state.loading = false
        state.userProductsSuccess = true
        state.userProducts = action.payload
      })
      .addCase(getUserProducts.rejected, (state) => {
        state.loading = false
        state.userProductsSuccess = false
      })
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaigns.fulfilled, (state,action) => {
        state.loading = false
        state.success = true
        state.campaigns = action.payload
      })
      .addCase(getCampaigns.rejected, (state) => {
        state.loading = false
        state.success = false
      })
      .addCase(getCampaign.pending, (state) => {
        state.campaignLoading = true
      })
      .addCase(getCampaign.fulfilled, (state,action) => {
        state.campaignLoading = false
        state.campaignSuccess = true
        state.editCampaign = action.payload
      })
      .addCase(getCampaign.rejected, (state) => {
        state.campaignLoading = false
        state.campaignSuccess = false
      })
      .addCase(getCampaignTypes.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignTypes.fulfilled, (state,action) => {
        state.loading = false
        state.success = true
        state.campaignTypes = action.payload
      })
      .addCase(getCampaignTypes.rejected, (state) => {
        state.loading = false
        state.success = false
      })
      .addCase(updateCampaign.pending, (state) => {
        state.campaignUpdateLoading = true
      })
      .addCase(updateCampaign.fulfilled, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = true
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = false
        state.showMessage = true
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.campaignDeleteLoading = true
      })
      .addCase(deleteCampaign.fulfilled, (state) => {
        state.campaignDeleteLoading = false
        state.campaignDeleteSuccess = true
      })
      .addCase(deleteCampaign.rejected, (state) => {
        state.campaignDeleteLoading = false
        state.campaignDeleteSuccess = false
      })
      .addCase(activeToggle.pending, (state) => {
        state.campaignToggleLoading = true
      })
      .addCase(activeToggle.fulfilled, (state) => {
        state.campaignToggleLoading = false
        state.campaignToggleSuccess = true
      })
      .addCase(activeToggle.rejected, (state) => {
        state.campaignToggleLoading = false
        state.campaignToggleSuccess = false
      })
      .addCase(resetVisits.pending, (state) => {
        state.campaignUpdateLoading = true
      })
      .addCase(resetVisits.fulfilled, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = true
      })
      .addCase(resetVisits.rejected, (state) => {
        state.campaignUpdateLoading = false
        state.campaignUpdateSuccess = false
        state.showMessage = true
      })
  }
})

export const { 
	showLoading,
  showCampaignUpdateModal,
  hideCampaignUpdateModal,
  clearEditCampaign,
  resetDeleteSuccess
} = dashboardSlice.actions

export default dashboardSlice.reducer