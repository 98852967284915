import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SplashLinkService from 'services/SplashLinkService';

export const initialState = {
  loading: false,
  showMessage: false,
  splashLinks: [],
  splashLink: {},
  showSplashLinkForm: false,
  splashLinkLoading:false,
  splashLinkSuccess:false,
  splashLinkUpdateLoading: false,
  splashLinkUpdateSuccess:false,
  splashLinkImage:null,
  splashLinkCroppedImage:null,
  deleteSplashlinkLoading:false
};

export const getLinks = createAsyncThunk('splashlink/links',async (_, { rejectWithValue }) => {
	try {
		const response = await SplashLinkService.getLinks();
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const updateSplashlink2 = createAsyncThunk('splashlink/updatesplashlink',async (data, { rejectWithValue }) => {
	try {
		const response = await SplashLinkService.updateSplashLink(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const getLink = createAsyncThunk('splashlink/link',async (link_id, { rejectWithValue }) => {
	try {
		const response = await SplashLinkService.getLink(link_id);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const updateSplashLink = createAsyncThunk('splashlink/updatesplashlink',async (data, { rejectWithValue }) => {
	try {
		const response = await SplashLinkService.updateSplashLink(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteSplashLinkImage = createAsyncThunk('splashlink/deletesplashlinkimage',async (data, { rejectWithValue }) => {
	try {
		const response = await SplashLinkService.deleteImage(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const resetVisits = createAsyncThunk('splashlink/resetvisits',async (data, { rejectWithValue }) => {
	try {
		const response = await SplashLinkService.resetVisits(data);
    return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const base64preview = createAsyncThunk('splashlink/resetvisits',async (data, { rejectWithValue }) => {

  console.log("CAMPAIGN ID IN SLICE:",data)
	try {
		await fetch('https://i.imgur.com/gBi0yjr.png')
    .then(response => response.blob())
    .then(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
        return(reader.result);
      }})
    })
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const SplashLinkSlice = createSlice({
  name: 'splashlinkslice',
  initialState,
  reducers:{
    showLoading:(state)=>{
      state.loading = true
    },
    showSplashLinkModal:(state)=>{
      state.showSplashLinkForm = true
    },
    hideSplashLinkModal:(state)=>{
      state.showSplashLinkForm = false
    },
    clearEditLink:(state)=>{
      state.splashLink = {};
    },
    setLinkImage:(state,action)=>{
      state.splashLinkImage = action.payload;
    },
    clearLinkImage:(state)=>{
      state.splashLinkImage = null
    },
    setCroppedImage:(state,action)=>{
      state.splashLinkCroppedImage = action.payload;
    },
    clearCroppedImage:(state)=>{
      state.splashLinkCroppedImage = null
    },
    resetUpdateSplashLinkUpdate:(state)=>{
      state.splashLinkUpdateSuccess = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinks.pending, (state) => {
        state.loading = true
      })
      .addCase(getLinks.fulfilled, (state,action) => {
        state.loading = false
        state.success = true
        state.splashLinks = action.payload
      })
      .addCase(getLinks.rejected, (state) => {
        state.loading = false
        state.success = false
      })
      .addCase(getLink.pending, (state) => {
        state.splashLinkLoading = true
      })
      .addCase(getLink.fulfilled, (state,action) => {
        state.splashLinkLoading = false
        state.splashLinkSuccess = true
        state.splashLink = action.payload
      })
      .addCase(getLink.rejected, (state) => {
        state.splashLinkLoading = false
        state.splashLinkSuccess = false
      })
      .addCase(updateSplashlink2.pending, (state) => {
        state.splashLinkUpdateLoading = true
      })
      .addCase(updateSplashlink2.fulfilled, (state) => {
        state.splashLinkUpdateLoading = false
        state.splashLinkUpdateSuccess = true
      })
      .addCase(updateSplashlink2.rejected, (state) => {
        state.splashLinkUpdateLoading = false
        state.splashLinkUpdateSuccess = false
        state.showMessage = true
      })
      .addCase(resetVisits.pending, (state) => {
        state.splashLinkUpdateLoading = true
      })
      .addCase(resetVisits.fulfilled, (state) => {
        state.splashLinkUpdateLoading = false
        state.splashLinkUpdateSuccess = true
      })
      .addCase(resetVisits.rejected, (state) => {
        state.splashLinkUpdateLoading = false
        state.splashLinkUpdateSuccess = false
        state.showMessage = true
      })
  }
});

export const { 
	showLoading,
  showSplashLinkModal,
  hideSplashLinkModal,
  clearEditLink,
  setLinkImage,
  clearLinkImage,
  setCroppedImage,
  clearCroppedImage,
  resetUpdateSplashLinkUpdate
} = SplashLinkSlice.actions;

export default SplashLinkSlice.reducer;