import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/api/users/login',
		method: 'post',
		data: data
	})
}

// AuthService.paymentIntent = function() {
// 	return fetch({
// 		url: '/api/payments/intent',
// 		method: 'post'
// 	})
// }

AuthService.checkemail = function(data) {
	return fetch({
		url: '/api/users/checkemail',
		method: 'post',
		data: data
	})
}

AuthService.resetpassword = function(data) {
	return fetch({
		url: '/api/users/resetpassword',
		method: 'post',
		data: data
	})
}

AuthService.updatepassword = function(data) {
	return fetch({
		url: '/api/users/updatepassword',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/api/users',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/api/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/api/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;