import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	stripeClientSecret:'',
	duplicateEmail: false,
	showPaymentForm: false,
	loggedInName:"",
	stripeCustomerId:"",
	passwordUpdateLoading:false,
	rewardfulCode:null
}
export const emailCheck = createAsyncThunk('auth/emailcheck',async (data, { rejectWithValue }) => {
	const { email } = data
	try {
		await AuthService.checkemail({email})
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const resetPassword = createAsyncThunk('auth/resetpassword',async (data, { rejectWithValue }) => {
	const { email } = data
	try {
		await AuthService.resetpassword({email})
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updatePassword = createAsyncThunk('auth/updatepassword',async (data, { rejectWithValue }) => {
	try {
		await AuthService.updatepassword(data)
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.login({email, password})
		const token = response.data.token;
		const loggedInName = response.data.name
		const stripeCustomerId = response.data.stripeCustomerId;
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem("STRIPE_CUSTOMER", stripeCustomerId);
		return {token,loggedInName,stripeCustomerId};
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// export const createPaymentIntent = createAsyncThunk('auth/payment/intent',async (_,{ rejectWithValue }) => {
// 	try {
// 		const response = await AuthService.paymentIntent()
// 		return response;
// 	} catch (err) {
// 		return rejectWithValue(err.response?.data?.message || 'Error')
// 	}
// })

export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.register(data)
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload.token
			state.loggedInName = action.payload.loggedInName
			state.stripeCustomerId = action.payload.stripeCustomerId
		},
		setRewardfulCode:(state,action)=>{
      state.rewardfulCode = action.payload;
    },
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.loggedInName = action.payload.loggedInName
				state.stripeCustomerId = action.payload.stripeCustomerId
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(updatePassword.pending, (state) => {
				state.passwordUpdateLoading = true
			})
			.addCase(updatePassword.fulfilled, (state, action) => {
				state.passwordUpdateLoading = false
				state.redirect = '/'
			})
			.addCase(updatePassword.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.passwordUpdateLoading = false
			})
			// .addCase(createPaymentIntent.pending, (state) => {
			// 	state.loading = true
			// })
			// .addCase(createPaymentIntent.fulfilled, (state,action) => {
			// 	state.loading = false
			// 	state.stripeClientSecret = action.payload
			// })
			// .addCase(createPaymentIntent.rejected, (state, action) => {
			// 	state.message = action.payload
			// 	state.showMessage = true
			// 	state.loading = false
			// })
			.addCase(emailCheck.rejected, (state,action) => {
				state.message = action.payload
				state.duplicateEmail = true
				state.showPaymentForm = false
				state.showMessage = true
			})
			.addCase(emailCheck.fulfilled, (state) => {
				state.duplicateEmail = false
				state.showPaymentForm = true
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	setRewardfulCode,
} = authSlice.actions

export default authSlice.reducer